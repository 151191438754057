const urlconfig = {
	frontend_server: process.env.REACT_APP_FRONTEND_SERVER || 'https://oms.eyantra.com/api',
  frontend: process.env.REACT_APP_FRONTEND || 'https://oms.eyantra.com',
  backend_api: process.env.REACT_APP_BACKEND_API || 'https://oms.eyantra.com/api/v2',
};

console.log("url", urlconfig);

// export default urlconfig;
module.exports = urlconfig;
