import React, { useEffect, useState } from 'react';
import './styles.css'; // Custom CSS for additional styling
const urlconfig = require('./config/urlconfig');

const Dashboard = () => {
  const [csvData, setCsvData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [metricsType, setMetricsType] = useState('salesman');
  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    fetch(`${urlconfig.frontend_server}/api/v2/get-master-csv`)
      .then(response => response.json())
      .then(data => {
        setCsvData(data.data);
        setFilteredData(data.data);
        calculateMetrics(data.data, metricsType);
      })
      .catch(error => console.error('Error fetching CSV data:', error));
  }, [metricsType]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMetricsTypeChange = (e) => {
    setMetricsType(e.target.value);
    calculateMetrics(filteredData, e.target.value);
  };

  const calculateMetrics = (data, type) => {
    if (data.length > 0) {
      const headers = data[0];
      const metricsIndex = headers.indexOf(type === 'salesman' ? 'Sales POC' : 'Details');
      if (metricsIndex !== -1) {
        const metricsMap = data.slice(1).reduce((acc, row) => {
          const key = row[metricsIndex];
          if (acc[key]) {
            acc[key] += 1;
          } else {
            acc[key] = 1;
          }
          return acc;
        }, {});
        setMetrics(Object.entries(metricsMap));
      }
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const salesOrderNoIndex = csvData[0].indexOf('Sales Order No');
      if (salesOrderNoIndex !== -1) {
        const filtered = csvData.filter((row, index) => {
          return index === 0 || row[salesOrderNoIndex].toLowerCase().includes(searchTerm.toLowerCase());
        });
        setFilteredData(filtered);
        calculateMetrics(filtered, metricsType);
      }
    } else {
      setFilteredData(csvData);
      calculateMetrics(csvData, metricsType);
    }
  }, [searchTerm, csvData, metricsType]);

  return (
    <div className="container-fluid">
      <h2 className="text-center text-dark my-4">Dashboard</h2>
      <div className="row mb-4 align-items-center">
        <div className="col-md-6">
        <label className="fw-bold mb-2">Search Order ID:</label>
          <div className="input-group input-group-lg">
            <input
              type="text"
              className="form-control shadow-sm fs-6 fw-bold"
              placeholder="Search by Sales Order No"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="metricsType" className="font-weight-bold mb-0">Show Metrics By:</label>
            <select
              id="metricsType"
              className="form-control shadow-sm fs-6"
              value={metricsType}
              onChange={handleMetricsTypeChange}
            >
              <option value="salesman">Salesman</option>
              <option value="product">Product</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card shadow-lg border-light rounded">
            <div className="card-header bg-primary text-white">
              <h5>Salesman & Products analytics</h5>
            </div>
            <div className="card-body">
              {metrics.length > 0 ? (
                <div className="metrics-grid">
                  {metrics.map(([key, value], index) => (
                    <div key={index} className="metrics-item">
                      <div className="metrics-key">{key}</div>
                      <div className="metrics-value rounded fs-6">{value}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No metrics available</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Data Table Section */}
      <div className="table-container">
        {filteredData.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover shadow-sm bg-primary" id="dashboardTable">
              <thead className="thead-dark">
                <tr>
                  {filteredData[0].map((header, index) => (
                    <th key={index} className="text-center">{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex} className="highlight-on-hover">
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex} className="text-center">{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="alert alert-warning text-center" role="alert">
          loading...
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
