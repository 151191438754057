// import React, { createContext, useContext, useEffect, useState } from "react";
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { redirect } from "react-router-dom";



import React, { createContext, useContext, useEffect, useState } from "react";     //NEW FOR SESSION AND TOKEN GENERATION
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const urlconfig = require('./config/urlconfig');

// const msalConfig = {
//   auth: {
//     clientId: "788d8cc6-5086-4624-af32-765f2e36079f",
//     authority: "https://login.microsoftonline.com/8075ed4b-7a84-4c5e-a066-370a8effc36b",
//     redirectUri: `${urlconfig.frontend}/login`,
//   },

//   // auth :{
//   //   clientId: "62ff87ed-45d9-4318-af54-ba7d71fd8c76",
//   //   authority : "https://login.microsoftonline.com/da5284b0-ca6e-4343-ba1a-24725c532d09",
//   //   redirectUri: `${urlconfig.frontend}/login`,
//   // },

//   cache: {
//     cacheLocation: "sessionStorage", // This configures where your cache will be stored
//     storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//   },
// };

// const loginRequest = {
//   scopes: ["User.Read"],
// };

// const msalInstance = new PublicClientApplication(msalConfig);
// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [account, setAccount] = useState(null);
//   const [email, setEmail] = useState(null);
//   const [username, setUsername] = useState(null);


//   useEffect(() => {
//     const handleRedirect = async () => {
//       try {
//         console.log("Initializing MSAL...");
//         await msalInstance.initialize();
//         console.log("Initialized MSAL...");
//         const response = await msalInstance.handleRedirectPromise();

//         console.log("handleRedirectPromise response:", response);
//         if (response) {
//           setAccount(response.account);
//           setEmail(response.account.username);
//           setUsername(response.account.name);
//         } else {
//           const currentAccounts = msalInstance.getAllAccounts();
//           console.log("currentAccounts:", currentAccounts);
//           if (currentAccounts.length === 1) {
//             setAccount(currentAccounts[0]);
//             setEmail(currentAccounts[0].username);
//             setUsername(currentAccounts[0].name)
//           }
//         }
//       } catch (error) {
//         console.error("MSAL initialization or redirect error:", error);
//       }
//     };

//     handleRedirect();
//   }, []);


//   const signIn = () => {
//     msalInstance.loginRedirect(loginRequest);
//   };

//   const signOut = () => {
//     msalInstance.logoutRedirect();  // use logoutRedirect instead of logout
//     setEmail(null);
//   };

//   return (
//     <AuthContext.Provider value={{ account, signIn, signOut, email, username }}>
//       <MsalProvider instance={msalInstance}>{children}</MsalProvider>
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);     //-------------------------1 MAIN







const msalConfig = {
  auth: {
    clientId: "62ff87ed-45d9-4318-af54-ba7d71fd8c76",
    authority: "https://login.microsoftonline.com/da5284b0-ca6e-4343-ba1a-24725c532d09",
    redirectUri: `${urlconfig.frontend}/login`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    // storeAuthStateInCookie: false,  --<<main
    storeAuthStateInCookie: true,
  },
};

const loginRequest = {
  scopes: ["User.Read"],
};

const msalInstance = new PublicClientApplication(msalConfig);
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [email, setEmail] = useState(sessionStorage.getItem("email") || null);
  const [username, setUsername] = useState(sessionStorage.getItem("username") || null);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        console.log("Initializing MSAL...");
        await msalInstance.initialize();
        console.log("Initialized MSAL...");
        const response = await msalInstance.handleRedirectPromise();

        if (response) {
          const { account } = response;
          setAccount(account);
          setEmail(account.username);
          setUsername(account.name);

          // Store session details in sessionStorage
          sessionStorage.setItem("email", account.username);
          sessionStorage.setItem("username", account.name);
        } else {
          const currentAccounts = msalInstance.getAllAccounts();
          if (currentAccounts.length === 1) {
            const account = currentAccounts[0];
            setAccount(account);
            setEmail(account.username);
            setUsername(account.name);

            // Store session details in sessionStorage
            sessionStorage.setItem("email", account.username);
            sessionStorage.setItem("username", account.name);
          }
        }
      } catch (error) {
        console.error("MSAL initialization or redirect error:", error);
      }
    };

    handleRedirect();
  }, []);

  const signIn = () => {
    msalInstance.loginRedirect(loginRequest);
  };

  const signOut = () => {
    msalInstance.logoutRedirect(); // Redirect to logout
    setEmail(null);
    setUsername(null);
    setAccount(null);

    // Clear session details from sessionStorage
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("username");
  };

  return (
    <AuthContext.Provider value={{ account, signIn, signOut, email, username }}>
      <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
