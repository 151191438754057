import React, { useState, useEffect } from 'react';
import { Button, Col,Row } from 'react-bootstrap';
import SearchBox from 'react-search-box';
const urlconfig = require('../config/urlconfig');

const AdminDashboard = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]); 
  const [searchQuery, setSearchQuery] = useState(''); 

//   const response = await fetch(`${urlconfig.frontend_server}/api/items`);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${urlconfig.frontend_server}/api/items`);
        if (!response.ok) {
          throw new Error('Failed to fetch items from the server');
        }
        const data = await response.json();
        const sortedItems = data.items ? data.items : data;
        sortedItems.sort((a, b) => new Date(b.date) - new Date(a.date));
        // console.log(data);
        setItems(data.items || data);  
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch items from the server');
        setLoading(false);
      }
    };

    fetchData();
  }, []); 

  const groupBySalesOrderNo = (items) => {
    return items.reduce((acc, item) => {
      // Group items by salesOrderNo
      if (!acc[item.salesOrderNo]) {
        acc[item.salesOrderNo] = [];
      }
      acc[item.salesOrderNo].push(item);
      return acc;
    }, {});
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle errors if any
  if (error) {
    return <div>{error}</div>;
  }

  // const handleViewModal = (item)=>{
  //   setSelectedItem(item);
  //   setShowModal(true);
  // }
  const handleViewModal = (item) => {
    const salesOrderNo = item.salesOrderNo;
    // Filter all items with the same salesOrderNo
    const relatedItems = items.filter((i) => i.salesOrderNo === salesOrderNo);
    setSelectedItem(relatedItems); // Set the related items instead of a single item
    setShowModal(true); // Open modal
  };

  const handleCloseModal =()=>{
    setShowModal(false);
    setSelectedItem(null);
  }

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredItems(items); 
    } else {
      const filtered = items.filter((item) =>
        (item.salesOrderNo && item.salesOrderNo.toLowerCase().includes(query.toLowerCase())) ||
        (item.clientName && item.clientName.toLowerCase().includes(query.toLowerCase())) ||
        (item.salesPOC && item.salesPOC.toLowerCase().includes(query.toLowerCase())) ||
        (item.createdBy && item.createdBy.toLowerCase().includes(query.toLowerCase()))
      );
      setFilteredItems(filtered); 
    }
  };
  const groupedItems = groupBySalesOrderNo(filteredItems.length > 0 ? filteredItems : items);

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <SearchBox
        value={searchQuery}
        onChange={(query) => handleSearch(query)} 
        placeholder="Search by Sales Order No, Client Name, Created By or Sales SPOC "
      />
      <div className='table-responsive-md'>
      <table className='table '>
        <thead>
          <tr>
            {/* <th>Pending</th> */}
            <th>Date</th>
            <th>Cost Sheet For</th>
            <th>Client Name</th>
            {/* <th>pp</th> */}
            {/* <th>Client Payment Terms</th> */}
            <th>Sales Order No</th>
            {/* <th>Sales POC</th>
            <th>SCM SPOC</th> */}
            <th>Details</th>
            <th>Margin</th>
            <th>Total PP</th>
            <th>Total SP</th>
            <th>Created By</th>
            <th>Approval Status</th>
            <th>Action</th>
            
          </tr>
        </thead>
        <tbody>
          {/* {items.map((item) => ( */}
          {Object.keys(groupedItems).map((salesOrderNo) => {
              const itemsInOrder = groupedItems[salesOrderNo];
              const firstItem = itemsInOrder[0]; // Take the first item to display some static details
            return(
            // <tr key={item._id}>
            <tr key={salesOrderNo}>
              
              {/* <td>
              {console.log(item.pending)}

              {item.pending === "undefined" || item.pending === undefined || item.pending === null || item.pending === '' ? 'Pending' 
                : item.pending === "true" || item.pending === true
                ? 'Approved' 
                : item.pending === "false" || item.pending === false
                ? 'Rejected' 
                : 'Unknown'  
              }
              </td> */}
              <td>{new Date(firstItem.date).toLocaleDateString('en-GB')}</td>
              <td>{firstItem.costsheetfor}</td>
              <td>{firstItem.clientName}</td>
              {/* <td>{item.pp}</td> */}
              {/* <td>{item.clientPaymentTerms}</td> */}
              <td>{firstItem.salesOrderNo}</td>
              {/* <td>{item.salesPOC}</td>
              <td>{item.scmspoc}</td> */}
              {/* <td>{item.details}</td> */}
              <td>{itemsInOrder.map((item)=>item.details).join(", ")}</td>

                   <td>&#8377;{-((((firstItem.pp)+(firstItem.pp*firstItem.gst/100))*(firstItem.qty)+(firstItem.transportationTotalPp)+(firstItem.customizationTotalPp)+(firstItem.marketingTotalPp))-(((firstItem.sp)+(firstItem.sp*firstItem.gst/100))*(firstItem.qty)+(firstItem.transportationTotalSp)+(firstItem.customizationTotalSp)+(firstItem.marketingTotalSp))).toFixed(1)}</td>
              <td> &#8377;{(((firstItem.pp)+(firstItem.pp*firstItem.gst/100))*(firstItem.qty)+(firstItem.transportationTotalPp)+(firstItem.customizationTotalPp)+(firstItem.marketingTotalPp)).toFixed(1)}</td>
              <td>&#8377;{(((firstItem.sp)+(firstItem.sp*firstItem.gst/100))*(firstItem.qty)+(firstItem.transportationTotalSp)+(firstItem.customizationTotalSp)+(firstItem.marketingTotalSp)).toFixed(1)}</td>
              <td>{firstItem.createdBy}</td>
             
              {/* <td>{-((((firstItem.pp)+(firstItem.pp*item.gst/100))*(item.qty)+(item.transportationTotalPp)+(item.customizationTotalPp)+(item.marketingTotalPp))-(((item.sp)+(item.sp*item.gst/100))*(item.qty)+(item.transportationTotalSp)+(item.customizationTotalSp)+(item.marketingTotalSp))).toFixed(1)}</td>
              <td>{(((item.pp)+(item.pp*item.gst/100))*(item.qty)+(item.transportationTotalPp)+(item.customizationTotalPp)+(item.marketingTotalPp)).toFixed(1)}</td>
              <td>{(((item.sp)+(item.sp*item.gst/100))*(item.qty)+(item.transportationTotalSp)+(item.customizationTotalSp)+(item.marketingTotalSp)).toFixed(1)}</td>
              <td>{item.createdBy}</td> */}
               <td>
                  {firstItem.pending === "undefined" || firstItem.pending === undefined || firstItem.pending === null || firstItem.pending === '' 
                    ? 'Pending ' 
                    : firstItem.pending === "true" || firstItem.pending === true
                    ? 'Approved By ' 
                    : firstItem.pending === "false" || firstItem.pending === false
                    ? 'Rejected By ' 
                    : 'Unknown'}  
                     {firstItem.ApprovedBy}
                 </td>
              <td>
                  <button className="btn btn-primary" onClick={() => handleViewModal(firstItem)}>View</button> {/* View Button */}
                </td>
            </tr>
            );
            })}
        </tbody>
      </table>
      </div>
          {/**showModal */}
          {showModal && selectedItem && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content" style={{transform: 'translate(-50%, -0%)', width: "1100px"}}>
              <div className="modal-header">
              <Row className='w-100 d-flex justify-content-between align-items-center'>
                  <Col md={9}>
                {/* <h5 className="modal-title p-0">OrderNO:- {selectedItem.salesOrderNo}</h5> */}
                Sales Order No: {selectedItem[0]?.salesOrderNo} {/* Display first item salesOrderNo */}
                </Col>
                <Col md={2}>
                 <span className='float-end' onClick={()=>(setShowModal(false))}>
                    <Button >X</Button>
                    </span>
                    </Col>
                  </Row>
              </div>
              <div className='modal-body w-100'>
                {/* <p><b>Cost Sheet for:-</b> {selectedItem.costsheetfor}</p> */}
                <p><b>Cost Sheet for:-</b> {selectedItem[0]?.costsheetfor}</p>

                <table className="table table-bordered">
  <thead>
    {/* <tr>
      <th colSpan="15" className="text-center">Client Name: {selectedItem.clientName}</th>
    </tr> */}
    <tr>
      <th colSpan="15" className="text-center">COST SHEET</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td colSpan="8"><b>Date:</b> {new Date(selectedItem[0]?.date).toLocaleDateString()}</td>
      
      <td colSpan="7"><b>Created By:</b> {selectedItem[0]?.createdBy}</td>
    </tr>
    <tr>
      <td colSpan="8"><b>Name Of the Client:</b> {selectedItem[0]?.clientName}</td>
      
      <td colSpan="7"><b>Sales Order No:</b> {selectedItem[0]?.salesOrderNo}</td>
    </tr>
    <tr>
      <td colSpan={4}><b>Sales SPOC:</b> {selectedItem[0]?.salesPOC}</td>
      <td colSpan={4}><b>SCM SPOC:</b> {selectedItem[0]?.scmspoc}</td>
      <td colSpan={5}><b>Client Payment terms:</b> {selectedItem[0]?.clientPaymentTerms}</td>
      
     
    </tr>
    <tr>
      <th>Details</th>
      <th>Qty</th>
      <th>GST</th>
      <th>PP (excl GST)</th>
      <th>PP (incl GST)</th>
      <th>SP (excl GST)</th>
      <th>SP (incl GST)</th>
      <th>Total PP (incl GST)</th>
      <th>Total SP (incl GST)</th>
      <th>Margin</th>
      <th>Margin %</th>
      <th>Vendor</th>
      <th>Payment terms</th>
    </tr>

    {/* Loop through each item in selectedSalesOrder */}
    {selectedItem.map((item, index) => (

    <tr key={index}>
      <td>{item.details}</td>
      <td>{item.qty}</td>
      <td>{item.gst}%</td>
      <td>{item.pp}</td>
      <td>{item.nlc}</td>
      <td>{item.sp}</td>
      <td>{item.nsp}</td>
      <td>{(item.qty) * (item.nlc).toFixed(0)}</td>
      <td>{((item.qty)*(item.nsp)).toFixed(1)}</td>
      <td>{item.margin}</td>
      <td>{item.marginPercent}</td>
      <td>{item.vendorName}</td>
      <td>{item.vendorPaymentTerms}</td>
    </tr>
    ))}

    {/* Additional rows for sub-total, transport, customization, etc. */}

    <tr>
      <td>Transportation</td>
      <td colSpan="6"></td>
      <td>{selectedItem[0]?.transportationTotalPp}</td>
      <td>{selectedItem[0]?.transportationTotalSp}</td>
      <td>{(selectedItem[0]?.transportationTotalSp)-(selectedItem[0]?.transportationTotalPp)}</td>
      <td>{(((selectedItem[0]?.transportationTotalSp)-(selectedItem[0]?.transportationTotalPp))/(selectedItem[0]?.transportationTotalPp)*100).toFixed(1)}%</td>
      <td colSpan="2"></td>
    </tr>
    <tr>
      <td>Customization</td>
      <td colSpan="6"></td>
      <td>{selectedItem[0]?.customizationTotalPp}</td>
      <td>{selectedItem[0]?.customizationTotalSp}</td>
      <td>{(selectedItem[0]?.customizationTotalSp)-(selectedItem[0]?.customizationTotalPp)}</td>
      <td>{(((selectedItem[0]?.customizationTotalSp)-(selectedItem[0]?.customizationTotalPp))/(selectedItem[0]?.customizationTotalPp)*100).toFixed(1)}%</td>
      <td colSpan="2"></td>
    </tr>
    <tr>
      <td>Marketing</td>
      <td colSpan="6"></td>
      <td>{selectedItem[0]?.marketingTotalPp}</td>
      <td>{selectedItem[0]?.marketingTotalSp}</td>
      <td>{(selectedItem[0]?.marketingTotalSp)-(selectedItem[0]?.marketingTotalPp)}</td>
      <td>{(((selectedItem[0]?.marketingTotalSp)-(selectedItem[0]?.marketingTotalPp))/(selectedItem[0]?.marketingTotalPp)*100).toFixed(1)}</td>
      <td colSpan="2"></td>
    </tr>
    {/* <tr>
      <td>Sub-Total</td>
      <td colSpan="6"></td>
      <td>{parseFloat((selectedItem.qty) * (selectedItem.nlc)+(selectedItem.transportationTotalPp)+(selectedItem.marketingTotalPp)+(selectedItem.customizationTotalPp)).toFixed(1)}</td>
      <td>{((selectedItem.qty)*(selectedItem.nsp)+(selectedItem.transportationTotalSp)+(selectedItem.customizationTotalSp)+(selectedItem.marketingTotalSp))}</td>
      <td>{((selectedItem.margin)+((selectedItem.transportationTotalSp)-(selectedItem.transportationTotalPp))+((selectedItem.customizationTotalSp)-(selectedItem.customizationTotalPp))+(selectedItem.marketingTotalSp)-(selectedItem.marketingTotalPp))} </td>
      <td>{(((selectedItem.margin)+((selectedItem.transportationTotalSp)-(selectedItem.transportationTotalPp))+((selectedItem.customizationTotalSp)-(selectedItem.customizationTotalPp))+(selectedItem.marketingTotalSp)-(selectedItem.marketingTotalPp))/((selectedItem.qty) * (selectedItem.nlc)+(selectedItem.transportationTotalPp)+(selectedItem.marketingTotalPp)+(selectedItem.customizationTotalPp))*100)}%</td>
      <td colSpan="2"></td>
    </tr> */}
    <tr>
  <td>Sub-Total</td>
  <td colSpan="6"></td>
  
  {/* Total PP (incl GST) calculation */}
  <td>
  ₹{parseFloat(selectedItem.reduce((acc, item) => acc + (item.qty * item.nlc), 0) +
    (selectedItem[0]?.transportationTotalPp || 0) +
    (selectedItem[0]?.marketingTotalPp || 0) +
    (selectedItem[0]?.customizationTotalPp || 0)).toFixed(1)}
  </td>
  
  {/* Total SP (incl GST) calculation */}
  <td>
  ₹ {parseFloat(selectedItem.reduce((acc, item) => acc + (item.qty * item.nsp), 0) +
    (selectedItem[0]?.transportationTotalSp || 0) +
    (selectedItem[0]?.marketingTotalSp || 0) +
    (selectedItem[0]?.customizationTotalSp || 0)).toFixed(1).toLocaleString('en-IN')}
  </td>
  
  {/* Margin Calculation */}
  <td>
    ₹ {parseFloat(
      selectedItem.reduce((acc, item) => acc + (item.qty * item.nsp), 0) -
      selectedItem.reduce((acc, item) => acc + (item.qty * item.nlc), 0) +
      (selectedItem[0]?.transportationTotalSp - selectedItem[0]?.transportationTotalPp || 0) +
      (selectedItem[0]?.customizationTotalSp - selectedItem[0]?.customizationTotalPp || 0) +
      (selectedItem[0]?.marketingTotalSp - selectedItem[0]?.marketingTotalPp || 0)
    ).toFixed(1).toLocaleString('en-IN')}
  </td>

  {/* Margin Percentage */}
  <td>
    {parseFloat(
      (selectedItem.reduce((acc, item) => acc + (item.qty * item.nsp), 0) -
      selectedItem.reduce((acc, item) => acc + (item.qty * item.nlc), 0) +
      (selectedItem[0]?.transportationTotalSp - selectedItem[0]?.transportationTotalPp || 0) +
      (selectedItem[0]?.customizationTotalSp - selectedItem[0]?.customizationTotalPp || 0) +
      (selectedItem[0]?.marketingTotalSp - selectedItem[0]?.marketingTotalPp || 0)) /
      (selectedItem.reduce((acc, item) => acc + (item.qty * item.nlc), 0) +
      (selectedItem[0]?.transportationTotalPp || 0) +
      (selectedItem[0]?.marketingTotalPp || 0) +
      (selectedItem[0]?.customizationTotalPp || 0)) * 100
    ).toFixed(1)}%
  </td>
  
  <td colSpan="2"></td>
</tr>

    {/* <tr> */}
      {/* <td>Sub-Total</td>
      <td colSpan={6}></td>
      <td>{selectedItem.reduce((acc, item) => acc + (item.qty * item.nlc + item.transportationTotalPp + item.marketingTotalPp + item.customizationTotalPp), 0).toFixed(1)}</td>
      <td>{selectedItem.reduce((acc, item) => acc + (item.qty * item.nsp + item.transportationTotalSp + item.customizationTotalSp + item.marketingTotalSp), 0)}</td>
      <td>{selectedItem.reduce((acc, item) => acc + (item.margin + (item.transportationTotalSp - item.transportationTotalPp) + (item.customizationTotalSp - item.customizationTotalPp) + (item.marketingTotalSp - item.marketingTotalPp)), 0)}</td>
      <td>{(
      selectedItem.reduce((acc, item) => acc + (item.margin + (item.transportationTotalSp - item.transportationTotalPp) + (item.customizationTotalSp - item.customizationTotalPp) + (item.marketingTotalSp - item.marketingTotalPp)), 0) /
      selectedItem.reduce((acc, item) => acc + (item.qty * item.nlc + item.transportationTotalPp + item.marketingTotalPp + item.customizationTotalPp), 0)
    * 100).toFixed(2)}%</td>
    </tr> */}

  </tbody>
</table>
        
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default AdminDashboard;
