import React, { useEffect } from 'react';
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Import the CSS file

const Login = () => {
  const { signIn, account } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (account) {
      navigate('/');
    }

  }, [account, navigate]);

  return (
    <div className="login-container">
      <h2>Welcome to eYantra!</h2>
      <button onClick={signIn}>Login with Microsoft</button>
    </div>
  );
};

export default Login;
