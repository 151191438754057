import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthProvider";
import { Row, Col } from "react-bootstrap";
const urlconfig = require('../config/urlconfig');

const UserDashboard = () => {
  const { account, username } = useAuth();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [originalItem, setOriginalItem] = useState(null);
  // const [groupitems, setGroupitems] = useState([])

  const groupItemsBySalesOrderNo = (items) => {
    return items.reduce((groups, item) => {
      if (!groups[item.salesOrderNo]) {
        groups[item.salesOrderNo] = [];
      }
      groups[item.salesOrderNo].push(item);
      return groups;
    }, {});
  };
  const filteredItems = items.filter((item) => item.createdBy === username);
  const [groupitems, setGroupitems] = useState();
  useEffect(()=>{
    // setGroupitems(groupItemsBySalesOrderNo(filteredItems))
    if(!groupitems && filteredItems.length){
      setGroupitems(groupItemsBySalesOrderNo(filteredItems));
      console.log(filteredItems, '>>>>>>>>>>>>>>>>')
    }
    
  }, [filteredItems])

  const groupedItems = groupItemsBySalesOrderNo(filteredItems);

  const fetchData = async () => {
    try {
      const response = await fetch(`${urlconfig.frontend_server}/api/items`);
      if (!response.ok) {
        throw new Error("Failed to fetch items from the server");
      }
      const data = await response.json();
      const sortedItems = data.items ? data.items : data;
      sortedItems.sort((a, b) => new Date(b.date) - new Date(a.date));
      setItems(data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch items from the server");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // display the data when createdby = username
  // const filteredItems = items.filter(item => item.createdBy === username);

  const handleEditClick = (item) => {
    setSelectedItem({ ...item });
    setOriginalItem({ ...item });
    setShowModal(true);
  };

  // const handleInputChange = (field, value, index, orderId) => {
  //   setSelectedItem({
  //     ...selectedItem,
  //     [field]: value,
  //     // ...item,

  //   });
    
  // }
//   const handleInputChange = (field, value, index, orderId) => {
//     // Update selectedItem
//     setSelectedItem(prevSelectedItem => ({
//       ...prevSelectedItem,
//       [field]: value,
//     }));
  
//     // Ensure groupitems[orderId] exists and is an array
//     if (groupitems[orderId] && Array.isArray(groupitems[orderId])) {
//       // Handle update for the specific order in groupitems
//       const newList = groupitems[orderId].map((item, key) => {
//         if (key === index) {
//           // Create a new object for the updated item
//           return {
//             ...item,
//             [field]: value,
//           };
//         }
//         return item;
//       });
  
//       // Update the groupitems with the new list for the specific order
//       setGroupitems(prevGroupItems => ({
//         ...prevGroupItems,
//         [orderId]: newList, // Only update the specific orderId
//       }));
//     } else {
//       // Handle the case when orderId doesn't exist or is not an array
//       console.error(`Error: orderId ${orderId} not found or is not an array.`);
//     }
//   };


const handleInputChange = (field, value, index, orderId) => {
    
    setSelectedItem(prevSelectedItem => ({
      ...prevSelectedItem,
      [field]: value,
    }));

    // // Log to check the values
    // console.log('groupitems:', groupitems);
    // console.log('selectedItem:', selectedItem);
    // console.log('orderId:', orderId);
    if (groupitems[orderId] && Array.isArray(groupitems[orderId])) {
      const newList = groupitems[orderId].map((item, key) => {
        if (key === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });
    //   console.log('newList:', newList);

      setGroupitems(prevGroupItems => ({
        ...prevGroupItems,
        [orderId]: newList, 
      }));
    } else {
    
      console.error(`Error: orderId ${orderId} not found or is not an array.`);
    }
};

  
  
  
  
  //   const NewhandleInputChange = (field, value, index, orderId) => {
  //     // console.log(groupedItems, '>>>>>>>>>>')
  //     // console.log( groupitems[orderId])
      
  //   const newList = groupitems[orderId].map((items, key) => {
      
  //     if (key == index) {
  //       const newObj = {
  //         ...items,
  //         [field]: value,
  //       }
  //       return (newObj);
        
  //     } else {
  //       return items;
  //     }
  //   });
  //   console.log(newList)
  //   setGroupitems({
  //     ...groupitems,
  //     // [groupitems[orderId]]: [...newList],
  //     [orderId]: newList,
  //   });
  //   console.log(field, value, index);
  // };

  
  const hasChanges =
    JSON.stringify(selectedItem) !== JSON.stringify(originalItem);
    
  const handleSaveEdit = async () => {
    try {
      const updatedData = {
        // add the fields name here to update
        costsheetfor: selectedItem.costsheetfor,
        createdBy: selectedItem.createdBy,
        clientName: selectedItem.clientName,
        salesPOC: selectedItem.salesPOC,
        scmspoc: selectedItem.scmspoc,
        details: selectedItem.details,
        margin: selectedItem.margin,
        clientPaymentTerms: selectedItem.clientPaymentTerms,
        qty: selectedItem.qty,
        gst: selectedItem.gst,
        pp: selectedItem.pp,
        sp: selectedItem.sp,
        vendorName: selectedItem.vendorName,
        transportationTotalPp: selectedItem.transportationTotalPp,
        transportationTotalSp: selectedItem.transportationTotalSp,
        customizationTotalPp: selectedItem.customizationTotalPp,
        customizationTotalSp: selectedItem.customizationTotalSp,
        marketingTotalPp: selectedItem.marketingTotalPp,
        marketingTotalSp: selectedItem.marketingTotalSp,
      };

      // console.log('Sending updated data:', updatedData);

      const response = await fetch(
        `${urlconfig.backend_api}/api/v2/edit-costsheet/${selectedItem._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );
      await sendCostSheetEmail();
      if (response.ok) {
        const data = await response.json();
        console.log("Updated Item:", data);
        // Optionally, update your UI with the updated data
        // setItems(items.map(item => item._id === selectedItem._id ? data : item));
        setShowModal(false);
        fetchData();
      } else {
        const errorData = await response.json();
        console.error("Error:", errorData.error);
      }
      if(!response){
        const errorData = await response.json();
  console.error("Error:", errorData.error);
  console.error("Response Status:", response.status);
      }
    } catch (error) {
      console.error("Error saving edit:", error);
    }
  };

  // Handle errors
  // if (error) {
  //   return <div>{error}</div>;
  // }

  if (loading) {
    return <div>Loading...</div>;
  }

  // const totalPP = ((selectedItem.qty * (selectedItem.pp + selectedItem.pp * selectedItem.gst / 100)) +
  //               selectedItem.transportationTotalPp +
  //               selectedItem.customizationTotalPp +
  //               selectedItem.marketingTotalPp).toFixed(3);
  // const totalPP=(selectedItem.qty * (selectedItem.pp + selectedItem.pp * selectedItem.gst / 100 )).toFixed(3)

  const sendCostSheetEmail = async (data) => {
    const response = await fetch(`${urlconfig.backend_api}/api/v2/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(data),
      body: JSON.stringify({
        salesOrderNo: selectedItem.salesOrderNo,
        createdBy: selectedItem.createdBy,
      }),
    });

    if (response.ok) {
      console.log("Cost sheet sent successfully");
    } else {
      console.error("Failed to send cost sheet");
    }
  };

  // Render the component after `account` and `username` have been loaded from useAuth
  return (
    <div>
      <h1>User Dashboard</h1>
      <h3>Username: {username}</h3>
      <table>
        <thead>
          <tr>
            <th>Costsheet For</th>
            {/* <th>Approval Status</th> */}
            <th>Client Name</th>
            <th>Sales Order No</th>
            <th>Sales SPOC</th>
            <th>SCM SPOC</th>
            <th>Details</th>
            <th>Created By</th>
            <th>Approval Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedItems).map((salesOrderNo) => {
            const itemsInOrder = groupedItems[salesOrderNo];
            const firstItem = itemsInOrder[0];
            return (
              <tr key={salesOrderNo}>
                <td>{firstItem.costsheetfor}</td>
                {/* <td>{firstItem.pending ? "Approved" : 'Rejected'}</td> */}
                <td>{firstItem.clientName}</td>
                <td>{firstItem.salesOrderNo}</td>
                {/* <td>{firstItem.margin}</td> */}
                <td>{firstItem.salesPOC}</td>
                <td>{firstItem.scmspoc}</td>

                {/* <td>{firstItem.details}</td> */}
                <td>{itemsInOrder.map((item) => item.details).join(", ")}</td>
                <td>{firstItem.createdBy}</td>
                <td>
                  {firstItem.pending === "undefined" ||
                  firstItem.pending === undefined ||
                  firstItem.pending === null ||
                  firstItem.pending === ""
                    ? "Pending "
                    : firstItem.pending === "true" || firstItem.pending === true
                    ? "Approved By "
                    : firstItem.pending === "false" ||
                      firstItem.pending === false
                    ? "Rejected By "
                    : "Unknown"}
                  {firstItem.ApprovedBy}
                </td>
                <td>
                  <button
                    onClick={() => handleEditClick(firstItem)}
                    className="btn btn-primary"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Edit Modal */}
      {showModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div
              className="modal-content"
              style={{ transform: "translate(-50%, -0%)", width: "1100px" }}
            >
              <div className="modal-header">
                <h5 className="modal-title">Edit Your Cost Sheet</h5>
              </div>
              <div className="modal-body">
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="costsheetfor">Costsheet For</label>
                      <input
                        type="text"
                        className="form-control"
                        id="costsheetfor"
                        value={selectedItem.costsheetfor}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label htmlFor="createdBy">Created By</label>
                      <input
                        type="text"
                        className="form-control"
                        id="createdBy"
                        value={selectedItem.createdBy}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label htmlFor="clientName">Client Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="clientName"
                      value={selectedItem.clientName}
                      onChange={(e) =>
                        handleInputChange("clientName", e.target.value)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <label htmlFor="SalesPOC">Sales SPOC</label>
                    <input
                      type="text"
                      className="form-control"
                      id="SalesPOC"
                      value={selectedItem.salesPOC}
                      onChange={(e) =>
                        handleInputChange("salesPOC", e.target.value)
                      }
                    />
                  </Col>
                  <Col md={4} className="mt-0">
                    <label htmlFor="scmspoc">SCM SPOC</label>
                    <input
                      type="text"
                      className="form-control"
                      id="scmspoc"
                      value={selectedItem.scmspoc}
                      onChange={(e) =>
                        handleInputChange("scmspoc", e.target.value)
                      }
                    />
                  </Col>
                  <Col md={4} className="mt-2">
                    <label htmlFor="scmspoc">Client Payment Terms</label>
                    <input
                      type="text"
                      className="form-control"
                      id="scmspoc"
                      value={selectedItem.clientPaymentTerms}
                      onChange={(e) =>
                        handleInputChange("clientPaymentTerms", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <p className="mt-3 fs-4">
                  {" "}
                  <b>Oder Details </b>
                </p>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped text-nowrap overflow-x-scroll">
                    <thead>
                      <tr>
                        <th>Details</th> {/* Description of the item */}
                        <th>Qty</th> {/* Quantity of the item */}
                        <th>GST</th> {/* GST (Goods and Services Tax) rate */}
                        <th>Purchase Price</th>{" "}
                        {/* Purchase Price (PP) per item */}
                        <th>NLC</th> {/* Net Landing Cost (NLC) per item */}
                        <th>Selling Price</th>{" "}
                        {/* Selling Price (SP) per item */}
                        <th>NSP</th> {/* Net Selling Price (NSP) per item */}
                        <th>Total PP</th>{" "}
                        {/* Total Purchase Price (Qty x PP) */}
                        <th>Total SP</th> {/* Total Selling Price (Qty x SP) */}
                        <th>Margin</th> {/* Profit Margin (SP - PP) */}
                        <th>Margin %</th>{" "}
                        {/* Profit Margin Percentage ((SP - PP) / PP) */}
                        <th>Vendor</th> {/* Vendor's name */}
                        <th>Vendor Payment Terms</th>{" "}
                        {/* Payment terms agreed with the vendor */}
                      </tr>
                    </thead>
                    <tbody>
                      {/* First Item */}
                      {groupitems[selectedItem.salesOrderNo] &&
                        groupitems[selectedItem.salesOrderNo].map(
                          (item, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="details"
                                  value={item.details}
                                  // onChange={(e) =>
                                  //   handleInputChange(
                                  //     "details",
                                  //     e.target.value,
                                  //     index,
                                  //     selectedItem.salesOrderNo
                                  //   )
                                  // }
                                  onChange={(e)=>
                                    handleInputChange('details', e.target.value, index, selectedItem.salesOrderNo)
                                  }
                                />
                              </td>{" "}
                              {/* Item Description */}
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="qty"
                                  value={item.qty}
                                  // onChange={(e) =>
                                  //   handleInputChange("qty", e.target.value)
                                  // }
                                  onChange={(e)=>
                                    handleInputChange("qty", e.target.value, index, selectedItem.salesOrderNo)
                                  }
                                />
                              </td>{" "}
                              {/* Quantity of Item */}
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={item.gst}
                                  onChange={(e) =>
                                    handleInputChange("gst", e.target.value, index, selectedItem.salesOrderNo)
                                  }
                                />
                              </td>{" "}
                              {/* GST (18% tax rate) */}
                              <td>
                                ₹
                                <input
                                  type="number"
                                  className="form-control"
                                  value={item.pp}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "pp",
                                      parseFloat(e.target.value), index, selectedItem.salesOrderNo
                                    )
                                  }
                                />
                              </td>{" "}
                              {/* Purchase Price (₹200 per item) */}
                              <td>
                                ₹
                                {(item.pp + (item.pp * item.gst) / 100).toFixed(
                                  1
                                )}
                              </td>{" "}
                              {/*Net Landing Cost (₹180 per item)*/}
                              <td>
                                ₹
                                <input
                                  type="number"
                                  className="form-control"
                                  value={item.sp}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "sp",
                                      parseFloat(e.target.value), index, selectedItem.salesOrderNo
                                    )
                                  }
                                />
                              </td>{" "}
                              {/* Selling Price (₹250 per item) */}
                              <td>
                                ₹{item.sp + (item.sp * item.gst) / 100}
                              </td>{" "}
                              {/* Net Selling Price (₹230 per item) */}
                              <td>
                                ₹
                                {item.qty *
                                  (
                                    item.pp +
                                    (item.pp * item.gst) / 100
                                  ).toFixed(1)}
                              </td>{" "}
                              {/* Total Purchase Price */}
                              <td>
                                ₹
                                {(
                                  item.qty *
                                  (item.sp + (item.sp * item.gst) / 100)
                                ).toFixed(1)}{" "}
                              </td>{" "}
                              {/* Total Selling Price  */}
                              <td>
                                ₹
                                {(
                                  item.qty *
                                    (item.sp + (item.sp * item.gst) / 100) -
                                  item.qty *
                                    (item.pp + (item.pp * item.gst) / 100)
                                ).toFixed(1)}
                              </td>{" "}
                              {/* Profit Margin (₹2500 - ₹2000) */}
                              <td>
                                {" "}
                                {/**formula for (below margin/totalpurchaseprice)*100 */}
                                {(
                                  ((item.qty *
                                    (item.sp + (item.sp * item.gst) / 100) -
                                    item.qty *
                                      (item.pp + (item.pp * item.gst) / 100)) /
                                    (item.qty *
                                      (item.pp + (item.pp * item.gst) / 100))) *
                                  100
                                ).toFixed(1)}
                              </td>{" "}
                              {/* Profit Margin Percentage (₹500/₹2000 * 100) */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={item.vendorName}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "vendorName",
                                      e.target.value, index, selectedItem.salesOrderNo
                                    )
                                  }
                                />
                              </td>{" "}
                              {/* Vendor Name */}
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={item.vendorPaymentTerms}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "vendorPaymentTerms",
                                      e.target.value, index, selectedItem.salesOrderNo
                                    )
                                  }
                                />
                              </td>{" "}
                              {/* Payment Terms: Advance */}
                            </tr>
                          )
                        )}

                      {/* Transportation */}
                      <tr>
                        <td>Transportation</td>{" "}
                        {/* Description: Transportation cost */}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={selectedItem.transportationTotalPp}
                            onChange={(e) =>
                              handleInputChange(
                                "transportationTotalPp",
                                e.target.value
                              )
                            }
                          />{" "}
                          {/**transpotationPP */}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={selectedItem.transportationTotalSp}
                            onChange={(e) =>
                              handleInputChange(
                                "transportationTotalSp",
                                e.target.value
                              )
                            }
                          />{" "}
                          {/* Total Selling Price for Transportation */}
                        </td>
                        <td>
                          ₹
                          {selectedItem.transportationTotalSp -
                            selectedItem.transportationTotalPp}
                        </td>{" "}
                        {/* Profit Margin for Transportation (₹5500 - ₹5000) */}
                        <td>
                          {(
                            ((selectedItem.transportationTotalSp -
                              selectedItem.transportationTotalPp) /
                              selectedItem.transportationTotalPp) *
                            100
                          ).toFixed(1)}
                          %
                        </td>{" "}
                        {/* (transportation margin/transportationpp)*100 */}
                        <td></td>
                        <td></td>
                      </tr>
                      {/* Customization */}
                      <tr>
                        <td>Customization</td>{" "}
                        {/* Description: Customization cost */}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={selectedItem.customizationTotalPp}
                            onChange={(e) =>
                              handleInputChange(
                                "customizationTotalPp",
                                e.target.value
                              )
                            }
                          />{" "}
                          {/* Total Purchase Price for Customization */}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={selectedItem.customizationTotalSp}
                            onChange={(e) =>
                              handleInputChange(
                                "customizationTotalSp",
                                e.target.value
                              )
                            }
                          />{" "}
                          {/* Total Selling Price for Customization */}
                        </td>
                        <td>
                          ₹
                          {selectedItem.customizationTotalSp -
                            selectedItem.customizationTotalPp}
                        </td>{" "}
                        {/* Profit Margin for Customization (₹1200 - ₹1000) */}
                        <td>
                          {(
                            ((selectedItem.customizationTotalSp -
                              selectedItem.customizationTotalPp) /
                              selectedItem.customizationTotalPp) *
                            100
                          ).toFixed(1)}
                          %
                        </td>{" "}
                        {/* Margin Percentage (₹200/₹1000 * 100) */}
                        <td></td>
                        <td></td>
                      </tr>
                      {/* Marketing */}
                      <tr>
                        <td>Marketing</td> {/* Description: Marketing cost */}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={selectedItem.marketingTotalPp}
                            onChange={(e) =>
                              handleInputChange(
                                "marketingTotalPp",
                                e.target.value
                              )
                            }
                          />{" "}
                          {/* Total Purchase Price for Marketing */}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={selectedItem.marketingTotalSp}
                            onChange={(e) =>
                              handleInputChange(
                                "marketingTotalSp",
                                e.target.value
                              )
                            }
                          />{" "}
                          {/* Total Selling Price for Marketing */}
                        </td>
                        <td>
                          ₹
                          {selectedItem.marketingTotalSp -
                            selectedItem.marketingTotalPp}
                        </td>{" "}
                        {/* Profit Margin for Marketing (₹2200 - ₹2000) */}
                        <td>
                          {(
                            ((selectedItem.marketingTotalSp -
                              selectedItem.marketingTotalPp) /
                              selectedItem.marketingTotalPp) *
                            100
                          ).toFixed(1)}
                          %
                        </td>{" "}
                        {/* Margin Percentage (₹200/₹2000 * 100) */}
                        <td></td>
                        <td></td>
                      </tr>
                      {/* Total Row */}
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          ₹
                          {(
                            groupedItems[selectedItem.salesOrderNo].reduce(
                              (total, item) =>
                                total +
                                item.qty *
                                  (item.pp + (item.pp * item.gst) / 100),
                              0
                            ) +
                            parseFloat(selectedItem.transportationTotalPp) +
                            parseFloat(selectedItem.customizationTotalPp) +
                            parseFloat(selectedItem.marketingTotalPp)
                          ).toFixed(1)}
                        </td>
                        <td>
                          ₹
                          {(
                            groupedItems[selectedItem.salesOrderNo].reduce(
                              (total, item) =>
                                total +
                                item.qty *
                                  (item.sp + (item.sp * item.gst) / 100),
                              0
                            ) +
                            parseFloat(selectedItem.transportationTotalSp) +
                            parseFloat(selectedItem.customizationTotalSp) +
                            parseFloat(selectedItem.marketingTotalSp)
                          ).toFixed(1)}
                        </td>
                        <td>
                          ₹
                          {(
                            groupedItems[selectedItem.salesOrderNo].reduce(
                              (total, item) =>
                                total +
                                item.qty *
                                  (item.sp + (item.sp * item.gst) / 100),
                              0
                            ) -
                            groupedItems[selectedItem.salesOrderNo].reduce(
                              (total, item) =>
                                total +
                                item.qty *
                                  (item.pp + (item.pp * item.gst) / 100),
                              0
                            ) +
                            (parseFloat(selectedItem.marketingTotalSp) -
                              parseFloat(selectedItem.marketingTotalPp)) +
                            (parseFloat(selectedItem.transportationTotalSp) -
                              parseFloat(selectedItem.transportationTotalPp)) +
                            (parseFloat(selectedItem.customizationTotalSp) -
                              parseFloat(selectedItem.customizationTotalPp))
                          ).toFixed(1)}
                        </td>
                        <td>
                          {(
                            ((groupedItems[selectedItem.salesOrderNo].reduce(
                              (total, item) =>
                                total +
                                item.qty *
                                  (item.sp + (item.sp * item.gst) / 100),
                              0
                            ) -
                              groupedItems[selectedItem.salesOrderNo].reduce(
                                (total, item) =>
                                  total +
                                  item.qty *
                                    (item.pp + (item.pp * item.gst) / 100),
                                0
                              ) +
                              (parseFloat(selectedItem.marketingTotalSp) -
                                parseFloat(selectedItem.marketingTotalPp)) +
                              (parseFloat(selectedItem.transportationTotalSp) -
                                parseFloat(
                                  selectedItem.transportationTotalPp
                                )) +
                              (parseFloat(selectedItem.customizationTotalSp) -
                                parseFloat(
                                  selectedItem.customizationTotalPp
                                ))) /
                              (groupedItems[selectedItem.salesOrderNo].reduce(
                                (total, item) =>
                                  total +
                                  item.qty *
                                    (item.pp + (item.pp * item.gst) / 100),
                                0
                              ) +
                                parseFloat(selectedItem.transportationTotalPp) +
                                parseFloat(selectedItem.customizationTotalPp) +
                                parseFloat(selectedItem.marketingTotalPp))) *
                            100
                          ).toFixed(1)}
                          %
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <input
                    type="disabled"
                    className="form-control"
                    value="pending"
                    disabled
                    hidden
                  />{" "}
                  {/* Hidden input field for "pending" status */}
                </div>
                {/**End modal content */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                {/* Show Save button only if changes are made */}
                {hasChanges && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveEdit}
                  >
                    Save Changes
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
