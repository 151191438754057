import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import './styles.css'; // Import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
const urlconfig = require('./config/urlconfig');

// Set the app element for react-modal
Modal.setAppElement('#root');

const Approvals = () => {
  const [csvData, setCsvData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const uniqSO = new Set();

  useEffect(() => {
    fetch(`${urlconfig.frontend_server}/api/v2/get-master-csv`)
      .then(response => response.json())
      .then(data => {
        setCsvData(data.data);
        setFilteredData(data.data); // Initially display all data
      })
      .catch(error => console.error('Error fetching CSV data:', error));
  }, []);

  const filterData = useCallback((term) => {
    const salesOrderNoIndex = csvData[0]?.indexOf('Sales Order No');
    if (salesOrderNoIndex !== -1) {
      const filtered = csvData.filter((row, index) => {
        return index === 0 || row[salesOrderNoIndex].toLowerCase().includes(term.toLowerCase());
      });
      setFilteredData(filtered);
    }
  }, [csvData]);

  useEffect(() => {
    if (searchTerm) {
      filterData(searchTerm);
    } else {
      setFilteredData(csvData); // Reset to all data when search is cleared
    }
  }, [searchTerm, csvData, filterData]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterData(e.target.value);
  };

  const openApprovalDialog = (salesOrderNo) => {
    const salesOrderNoIndex = csvData[0]?.indexOf('Sales Order No');
    if (salesOrderNoIndex !== -1) {
      const items = csvData.filter((row, index) => index === 0 || row[salesOrderNoIndex] === salesOrderNo);
      setSelectedSalesOrder(items);
      setIsModalOpen(true);
    }
  };

  const closeApprovalDialog = () => {
    setIsModalOpen(false);
    setSelectedSalesOrder(null);
  };

  const handleApproval = (status) => {
    if (selectedSalesOrder && selectedSalesOrder.length > 1) {
      const salesOrderNo = selectedSalesOrder[1][csvData[0].indexOf('Sales Order No')];

      fetch(`${urlconfig.frontend_server}/api/update-approval-status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          salesOrderNo,
          status,
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const updatedData = csvData.map(row => {
            if (row[csvData[0].indexOf('Sales Order No')] === salesOrderNo) {
              row[csvData[0].indexOf('Approved')] = status ? 'true' : 'false';
            }
            return row;
          });
          setCsvData(updatedData);
          setFilteredData(updatedData);
        }
        closeApprovalDialog();
      })
      .catch(error => console.error('Error updating approval status:', error));
    }
  };  //this is main -----<<<<


 
  

  const subtotal = {
    totalPpInclGst: 0,
    totalSpInclGst: 0,
    totalNsp: 0,
    totalNlc: 0,
    margin: 0,
    marginPercent: 0,
  };

  if (selectedSalesOrder) {
    selectedSalesOrder.slice(1).forEach(item => {
      subtotal.totalPpInclGst += parseFloat(item[csvData[0].indexOf('Total PP')]) || 0;
      subtotal.totalSpInclGst += parseFloat(item[csvData[0].indexOf('Total SP')]) || 0;
      subtotal.margin += parseFloat(item[csvData[0].indexOf('Margin')]) || 0;
      let itemMarginPercent = parseFloat(item[csvData[0].indexOf('Margin %')]) || 0;
      subtotal.marginPercent += itemMarginPercent;
    });
    subtotal.marginPercent = Math.min(subtotal.marginPercent, 100);
  }

  return (
    <div className="container">
      <h2 className="my-4">Approval Dashboard</h2>
      <div className="row mb-4">
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Search by Sales Order No"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      {filteredData.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="thead-light">
              <tr>
                <th>Approved</th>
                <th>Date</th>
                <th>Client Name</th>
                <th>Payment Terms</th>
                <th>Sales Order No</th>
                <th>Sales POC</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.slice(1).map((row, rowIndex) => {
                const salesOrderNo = row[csvData[0].indexOf('Sales Order No')];
                if (uniqSO.has(salesOrderNo)) {
                  return null;
                } else {
                  uniqSO.add(salesOrderNo);
                  return (
                    <tr
                      key={rowIndex}
                      className="table-row-hover"
                      onClick={() => openApprovalDialog(salesOrderNo)}
                    >
                      <td className={row[csvData[0].indexOf('Approved')] === 'true' ? 'text-success' : 'text-danger'}>
                        {row[csvData[0].indexOf('Approved')] === 'true' ? 'Approved' : 'Rejected'}
                      </td>
                      <td>{row[csvData[0].indexOf('Date')]}</td>
                      <td>{row[csvData[0].indexOf('Client Name')]}</td>
                      <td>{row[csvData[0].indexOf('Payment Terms')]}</td>
                      <td>{salesOrderNo}</td>
                      <td>{row[csvData[0].indexOf('Sales POC')]}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Loading data...</p>
      )}

      {/* Approval Dialog */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeApprovalDialog}
        contentLabel="Approval Dialog"
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        <div className="approval-dialog">
          {selectedSalesOrder && selectedSalesOrder.length > 1 ? (
            <>
              <h3>Approve or Reject Sales Order No: {selectedSalesOrder[1][csvData[0].indexOf('Sales Order No')]}</h3>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th colSpan="15" className="text-center">
                        {selectedSalesOrder[1][csvData[0].indexOf('Client Name')]}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan="15" className="text-center">COST SHEET</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="8">Date: {selectedSalesOrder[1][csvData[0].indexOf('Date')]}</td>
                      <td colSpan="7">Client Name: {selectedSalesOrder[1][csvData[0].indexOf('Client Name')]}</td>
                    </tr>
                    {/* Additional Rows */}
                    {/* Render rows dynamically as before */}
                    <tr>
                      <td colSpan="8">Payment Terms: byd</td>
                      <td colSpan="7">Sales Order No: {selectedSalesOrder[1][csvData[0].indexOf('Sales Order No')]}</td>
                    </tr>
                    <tr>
                      <th>Details</th>
                      <th>Qty</th>
                      <th>GST</th>
                      <th>PP (excl GST)</th>
                      <th>PP (incl GST)</th>
                      <th>SP (excl GST)</th>
                      <th>SP (incl GST)</th>
                      <th>Total PP (incl GST)</th>
                      <th>Total SP (incl GST)</th>
                      <th>Margin</th>
                      <th>Margin %</th>
                      <th>Vendor</th>
                      <th>Payment terms</th>
                    </tr>
                    {/* Loop through each item in selectedSalesOrder and render a row */}
                    {selectedSalesOrder.slice(1).map((item, rowIndex) => (

                                  <tr key={rowIndex}>
                                    <td>{item[csvData[0].indexOf('Details')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('Qty')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('GST')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('PP')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('NLC')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('SP')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('NSP')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('Total PP')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('Total SP')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('Margin')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('Margin %')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('Vendor')] || "N/A"}</td>
                                    <td>{item[csvData[0].indexOf('Payment Terms')] || "N/A"}</td>
                                  </tr>
                    ))}
                    {/* Additional rows */}
                    <tr>
                      <td>Sub-Total</td>
                      <td colSpan="6"></td>
                      <td>{subtotal.totalPpInclGst.toFixed(2)}</td>
                      <td>{subtotal.totalSpInclGst.toFixed(2)}</td>
                      <td>{subtotal.margin.toFixed(2)}</td>
                      <td>{subtotal.marginPercent.toFixed(2)}%</td>
                      <td colSpan="2"></td>
                    </tr>
                    <tr>
                    <td>Transportation</td>
                    <td colSpan="6"></td>
                    <td>{selectedSalesOrder[1][csvData[0].indexOf('Transport')]}</td>
                    <td colSpan="5"></td>
                    </tr>
                    <tr>
                    <td>Customization</td>
                    <td colSpan="6"></td>
                    <td>{selectedSalesOrder[1][csvData[0].indexOf('Customization')]}</td>
                    <td colSpan="5"></td>
                    </tr>
                    <tr>
                    <td>Marketing</td>
                    <td colSpan="6"></td>
                    <td>{selectedSalesOrder[1][csvData[0].indexOf('Marketing')]}</td>
                    <td colSpan="5"></td>
                    </tr>

                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-success"
                  onClick={() => handleApproval(true)}
                >
                  Approve
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleApproval(false)}
                >
                  Reject
                </button>
              </div>
            </>
          ) : (
            <p>No data available</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Approvals;
